import { DroitFamilleEnum, DroitInterneEnum } from '@_up/shared/models/enums/droit.enum';
import { User } from '@_up/shared/models/interfaces/user.model';

export namespace UserAuthUtils {
  let _authentifiedUser: User = null;

  export function currentUser(): User {
    return _authentifiedUser;
  }

  export function setCurrentUser(resultedUser: User): void {
    _authentifiedUser = resultedUser;
  }

  export function getUserRight(user: Partial<User> = UserAuthUtils.currentUser()): DroitInterneEnum[] {
    const rights: DroitInterneEnum[][] = user?.profils?.map(profil => profil.droits.map(droit => droit.labelIntern));
    return Array.prototype.concat.apply([], rights);
  }

  export function getUserAccessRight(user: Partial<User> = UserAuthUtils.currentUser()): DroitInterneEnum[] {
    const rights: DroitInterneEnum[][] = user?.profils?.map(profil =>
      profil.droits
        .filter(d => d.famille == DroitFamilleEnum.page)
        .map(droit => {
          return droit.labelIntern;
        })
    );
    return Array.prototype.concat.apply([], rights);
  }

  export function isUserAdmin(user = UserAuthUtils.currentUser()): boolean {
    return getUserRight(user).includes(DroitInterneEnum.manageAdmin);
  }

  export function userHaveRight(
    right: DroitInterneEnum | DroitInterneEnum[],
    user: Partial<User> = UserAuthUtils.currentUser()
  ): boolean {
    if (!right) {
      return false;
    } else if (typeof right === 'string') {
      return getUserRight(user).includes(right);
    } else if (Array.isArray(right)) {
      return !right.map(it => getUserRight(user).includes(it)).includes(false);
    } else {
      return false;
    }
  }

  export function userHaveNoAccessPage(): boolean {
    return getUserAccessRight().length === 0;
  }

  export function isUserSuperAdmin(user = UserAuthUtils.currentUser()): boolean {
    return !!user.superAdmin;
  }

  export function isUserOrgaSuperAdmin(user = currentUser): boolean {
    return !!user()?.superAdminOrganization;
  }
}
export default UserAuthUtils;
