import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from '@_up/environments/environment';

// TODO: Check nx cloud (to improve cache and CI performances)

if (environment.production) {
  enableProdMode();
}

function getCurrentEnv(): 'integration' | 'qualif' | 'production' | 'development' | 'atos' | 'test' {
  if (environment.integration) {
    return 'integration';
  } else if (environment.qualif) {
    return 'qualif';
  } else if (environment.atos) {
    return 'atos';
  } else if (environment.test) {
    return 'test';
  } else if (environment.production) {
    return 'production';
  } else {
    return 'development';
  }
}

Sentry.init({
  dsn: 'https://083e0b3dc8d64c7ba4ae060d1d8ef8c5@o1171300.ingest.sentry.io/6272982',
  environment: getCurrentEnv(),
  enabled: getCurrentEnv() !== 'development',
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['localhost', environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],
  tracesSampleRate: 0.5
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
