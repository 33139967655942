import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import SaleFamilyCategoryUtils from '@_up/utils/router/sale-family-category.util';

@Injectable()
export class SaleCategoryInterceptor implements HttpInterceptor {
  private avalaibleRoute: string[] = [
    'api/board-state',
    'api/dashboard/stock?',
    'api/dashboard/activity',
    'api/dashboard/saleLeadActivityByStratalot?',
    'api/dashboard/leadActivityByStratalot?',
    'api/dashboard/saleActivityByResidence?',
    'api/dashboard/forecast?',
    'api/lead/forSalesInProgressBoard?',
    'api/residence?',
    'api/residence/',
    'api/residence/getResidencesByCompanies?',
    'api/residence/getResidencesOccupation?',
    'api/residence/montage?',
    'api/residence/forSales?',
    'api/stratalot/by-step-progresses?',
    'api/stratalot/forValidateOfferBoard?',
    'api/stratalot/free?',
    'api/stratalot/ubiflowMonitoring?',
    'api/stratalot/from-residence?',
    'api/stratalot/prix-acquereur'
  ];

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (request.method !== 'GET' || !this.checkIfRequestNeedSaleCategorie(request.urlWithParams)) {
      return next.handle(request);
    }
    const newRequest = request.clone({
      setParams: { ['saleCategory']: SaleFamilyCategoryUtils.getActiveSaleFamilyCategory() }
    });
    return next.handle(newRequest);
  }
  private checkIfRequestNeedSaleCategorie(url: string): boolean {
    return this.avalaibleRoute.some(route => url.includes(route));
  }
}
