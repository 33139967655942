import { NgModule } from "@angular/core";
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routerOptions: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  relativeLinkResolution: 'corrected',
  paramsInheritanceStrategy: 'always'
};

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
