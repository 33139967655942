import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import SaleFamilyCategoryUtils from '@_up/utils/router/sale-family-category.util';

@Injectable()
export class BoardStateInterceptor implements HttpInterceptor {
  private avalaibleRoute: string[] = ['api/board-state'];

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (request.method !== 'POST' || !this.checkIfRequestNeedSaleCategorie(request.urlWithParams)) {
      return next.handle(request);
    }
    const newRequest = request.clone({
      body: { ...request.body, saleCategory: SaleFamilyCategoryUtils.getActiveSaleFamilyCategory() }
    });
    return next.handle(newRequest);
  }
  private checkIfRequestNeedSaleCategorie(url: string): boolean {
    return this.avalaibleRoute.some(route => url.includes(route));
  }
}
