import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@_up/environments/environment';
import { LoginData } from '@_up/shared/interfaces/login-data.interface';
import { firstValueFrom, Observable } from 'rxjs';
import { MessageBusService } from './errors/message-bus.service';
import { StorageService } from './storage.service';
import UserAuthUtils from './store/user-auth-utils';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    private messageBusService: MessageBusService
  ) {}

  public isLogged = false;
  public loginData: LoginData;

  public login(credentials: { email: string; password: string }): Observable<any> {
    return this.http.post(environment.apiUrl + '/auth/login', credentials);
  }

  public reset(credentials: { password: string; token: string }): Observable<any> {
    return this.http.put(environment.apiUrl + '/auth/reset', credentials);
  }

  //fix
  public sendReset(credentials: { email: string; type: string }): Observable<any> {
    return this.http.post(environment.apiUrl + '/auth/send-reset-email', credentials);
  }

  public tokenUser(credentials: { token: string }): Observable<any> {
    return this.http.get(environment.apiUrl + '/auth/token-user?token=' + credentials.token);
  }

  public renew(): Observable<any> {
    return this.http.get(environment.apiUrl + '/auth/renew');
  }

  public getLoginStorage(): void {
    const loginData = this.storageService.isLogged();
    if (!loginData) {
      return;
    } else {
      this.loginData = loginData;
      this.isLogged = true;
    }
  }

  public async logout(): Promise<void> {
    await firstValueFrom(this.http.delete(environment.apiUrl + '/auth/logout'));
    this.storageService.clearAll();
    UserAuthUtils.setCurrentUser(null);
    this.loginData = undefined;
    this.isLogged = false;
    this.messageBusService.pushAuthMessage(AuthMessage.logout);
  }
}

export enum AuthMessage {
  logout = 'logout',
  redirectAfterLogin = "Vous allez être redirigé vers la page d'accueil"
}
