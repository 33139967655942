import { Injectable } from '@angular/core';
import { AuthMessage } from '@_up/services/auth.service';
import { ErrorRoutingMessage } from 'apps/up/src/app/modules/logged/error-routing-message.enum';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageBusService {
  private errorMessage$ = new ReplaySubject<string>(1);
  private authErrorMessage$ = new ReplaySubject<string>(1);
  private authMessage$ = new ReplaySubject<AuthMessage>(1);
  private routingErrorMessage$ = new ReplaySubject<ErrorRoutingMessage>(1);
  public pushAuthMessage(message: AuthMessage): void {
    this.authMessage$.next(message);
  }
  public selectAuthMessage(): Observable<AuthMessage> {
    return this.authMessage$.asObservable();
  }
  public getClientErrorMessage(error: Error): string {
    return error.message ? error.message : error.toString();
  }
  public pushNewErrorMessage(message: string): void {
    this.errorMessage$.next(message);
  }
  public selectErrorMessage(): Observable<string> {
    return this.errorMessage$.asObservable();
  }
  getRoutingMessage(error: Error): string {
    return error.message ? error.message : error.toString();
  }
  public pushNewRoutingMessage(message: ErrorRoutingMessage): void {
    this.routingErrorMessage$.next(message);
  }
  public selectedErrorRoutingMessage(): Observable<string> {
    return this.routingErrorMessage$.asObservable();
  }
  public pushNewAuthErrorMessage(message: string): void {
    this.authErrorMessage$.next(message);
  }
  public selectedAuthErrorMessage(): Observable<string> {
    return this.authErrorMessage$.asObservable();
  }
}
