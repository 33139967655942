import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MessageBusService } from '@_up/services/errors/message-bus.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private readonly errorService: MessageBusService) {}

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Error from http error interceptor', error);
        if (
          error.status === 401 &&
          error.error.subCode !== 'E_INVALID_TOKEN' &&
          error.error.subCode !== 'E_USER_WRONG_PASSWORD'
        ) {
          this.errorService.pushNewErrorMessage(error.error.message);
        } else if (error.status === 422 && error.error.message) {
          this.errorService.pushNewErrorMessage(error.error.message);
        }
        return throwError(() => error);
      })
    );
  }
}
